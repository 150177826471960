.investment-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-left: 50px;
    grid-column: full-start / full-end;
    width: 90%;
  }
  
  .investment-body {
    display: flex;
    margin-top: 20px;
  }
  
  
.image-gallery {
  display: flex;
  margin-right: 20px;
}

.thumbnail-images {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.thumbnail {
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.thumbnail.active, .thumbnail:hover {
  opacity: 1;
}
.investment-image {
  width: 100%;
  height: 700px; /* Adjust based on your needs */
  margin-right: 20px;
  overflow: hidden; /* To ensure images don't overflow the container */
}

.investment-image img {
  width: 100%;
  height: 100%; /* Make image fill the container */
  object-fit: cover; /* Ensures the aspect ratio is maintained without stretching the image */
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #a98559;
  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}


.investment-info {
  width: 40%; /* Adjust as needed */
  font-size: 1.2rem; /* Adjust the size as needed */
  /* Add other styles if needed, e.g., line-height for better readability */
  line-height: 1.2;
  /* ... other styles ... */
  
}

/* Style for your buttons */
.download-docs, .invest {
  /* Add button styling */
  color: #ffffff;/*text color*/
  background-color: #c69963;
  width: 75%;
  margin: 10px 0;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-docs:hover, .invest:hover {
  /* Hover effect */
  background-color: #B28451;
}

.investment-info-item {
  text-transform: capitalize;
}

.capital-stack {
  white-space: pre-line;
}