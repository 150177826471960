.filter {
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.filter.active .filter-body {
  display: block;
}

.filter-btn {
  background-color: #c69963;
  color: #fff; /* Text color */
  border: none;
  padding: 1.0rem 1.6rem;
  font-size: 1.2rem;
  border-radius: 1px; /* Rounded corners */
  margin-top: 2rem;
  margin-left: 5rem;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for background color */
  width: 42%;
  text-align: left;
  font-weight: bold;
  box-sizing: border-box;
}

.filter-btn:hover {
  background-color: #B28451; /* Darker shade for hover effect */
}

.filter-body {
  display: none; /* Hide initially */
  padding: 0.8rem 1.6rem; /* Padding to match filter button */
  margin-left: 5rem;
  margin-right: 46rem;
}

input,
select,
label {
  font-family: "Nunito", sans-serif;
}

select {
  margin-left: 1rem;
}

.checkbox-label {
  display: flex;
}

.checkbox-label input[type="checkbox"] {
  margin-left: -230px;
  margin-top: 4px;
}

.update-btn {
  background-color: #c69963;
  color: #fff;
  border: none;
  border-radius: 1px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.9rem 3rem;
  cursor: pointer;
  transition: all .2s;
  margin-left: auto;
  position: absolute;
  margin-left: 8rem;
}

.update-btn:hover {
  background-color: #B28451; /* Darker shade for hover effect */
}

.listing-btn {
  position: relative;
  left: 0px;
  grid-column: 1 / -1;
  background-color: #c69963;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 1.3rem 3rem;
  cursor: pointer;
  transition: all .2s;
}

.listing-btn:hover {
  background-color: #B28451;
}

.listings {
  grid-column: center-start / center-end;
  margin: 4rem 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  grid-gap: 7rem;
}

.listing {
  background-color: #f9f7f6;
  display: grid;
  grid-row-gap: 0rem;
  position: relative;
}

.listing__img {
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  z-index: 1;
}

.listing__like {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  fill: #c69963;
  height: 2.5rem;
  width: 2.5rem;
  z-index: 2;
  justify-self: end;
  margin: 1rem;
}

.listing__name {
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  justify-self: center;
  align-self: end;
  z-index: 3;
  width: 80%;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1rem;
  text-align: center;
  padding: 1.25rem;
  background-color: #101d2c;
  color: #fff;
  font-weight: 600;
  transform: translateY(100%);
}

.listing__developer {
  font-family: "Josefin Sans", sans-serif;
  color: #6D5D4B;
  font-weight: 100;
  line-height: 0;
  font-size: 0.65rem;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.listing__top {
  margin-top: 2.8rem;
}

.listing__bottom {
  margin-bottom: 0.9rem;
}

p {
  font-family: "Nunito", sans-serif;
}

.listing__detail {
  font-family: "Nunito", sans-serif;
  color: #6D5D4B;
  font-weight: 100;
  font-size: 1rem;
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  display: flex;
  align-items: center;
}

.listing__value {
  margin-left: auto;
  font-weight: bold;
  text-transform: capitalize;
}

.listing-btn {
  position: relative;
  left: 0px;
  grid-column: 1 / -1;
}