* {
    font-family: 'Josefin Sans', sans-serif;
}

.reset-btn {
    background-color: #c21818; /* Green background */
    color: white; /* White text color */
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    font-size: 1rem; /* Adjust font size as needed */
    transition: background-color 0.3s; /* Smooth transition for background color */
}

.reset-btn:hover {
    background-color: #680505; /* Slightly darker green for hover effect */
}