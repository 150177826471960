/* Footer.css */
.footer {
    background-color: #54483A;
    background-image: linear-gradient(rgba(19, 43, 46, 0.93), rgba(16, 29, 44, 0.93));
    color: white;
    text-align: center;
    padding: 4rem 2rem; /* Adjust padding as needed */
    position: relative;
  }
  
  .footer__content p {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
  }